/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins/euiEffects.scss';

.euiSuperSelectControl .platform-option {
  .platform-icon {
    justify-content: center;
    @media screen and (max-width: $euiBreakpointM) {
      width: 20%!important;
      flex-basis: 0!important;
    }
  }

  .euiText {
    background-color: #FBFCFD;

    .euiTheme-dark & {
      background-color: #16171C;
    }
  }

  .platform-name, .euiPanel .euiContextMenuItem__text .platform-name {
    @media screen and (max-width: $euiBreakpointM) {
      flex-basis: 0!important;
      width: 80%!important;
      padding-left: $paddingBase;
    }
  }
}

.euiSuperSelectControl:disabled .platform-option {
  .euiText {
    background-color: #EEF2F7;
  }
}

.euiSuperSelect__listbox .platform-option .platform-name {
  @media screen and (max-width: $euiBreakpointM) {
    flex-basis: 0!important;
    width: 80%!important;
    padding-left: $paddingBase;
  }
}

.euiSuperSelect__listbox .platform-option .platform-icon {
  @media screen and (max-width: $euiBreakpointM) {
    width: 20%!important;
    flex-basis: 0!important;
  }
}

.cloud-provider-disabled-tooltip {
  width: 100%;
}
