/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '../../styles/variables.scss';

.apiKeys-tableWrap {
  display: flex;
  flex-direction: column-reverse;
}

.apiKeys-table {
  // using the same max-widths constraints as for the text above the table
  max-width: 36em;
  min-width: 75%;
}

.apiKey-form {
  max-width: 600px;

  .apiKey-key {
    overflow-wrap: break-word;
  }
}

.apiKey-modal {
  min-width: 400px;

  &.keyStep {
    min-width: $euiBreakpointS;
    transition: min-width 500ms ease-in-out;
  }

  &.transitionHideContent {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  &.transitionShowContent {
    opacity: 1;
    transition: opacity 200ms ease-in-out;
  }
}
