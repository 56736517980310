/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '../../../../styles/variables.scss';

.deployment-costs-grid {
  .euiTableCellContent {
    .deployment-costs-grid-privacy-container {
      max-width: 100%;
    }

    .deployment-costs-grid-deployment-name {
      max-width: 100%;
      display: inline-flex;

      .deployment-costs-grid-deployment-name-link {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: $euiBreakpointS) {
          white-space: normal;
        }
      }
    }
  }
}
