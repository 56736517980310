/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '../../../styles/variables.scss';

.ilmSettings {
  .euiTitle+.euiTitle {
    margin-top: $paddingSmall;
  }
}

.ilmSettings-badge-recommended {
  background-color: $euiColorVis4;
  margin-left: $paddingSmall;
}

.ilmSettings-label {
  margin-top: $paddingSmall;
  margin-bottom: $paddingSmall;
  color: $euiColorMediumShade;
}

.ilmSettings-esIcon {
  margin-right: $euiSizeS;
}

.ilmSettings-arrowIcon {
  margin-top: $paddingLarge;
  margin-right: $paddingLargest;
}

.ilmSettings-arrowContainer {
  @media screen and (max-width: $euiBreakpointM) {
    display: none;
  }
}

.ilmSettings-instanceName {
  line-height: $euiSizeXXL;
  white-space: nowrap;
}

.ilmSettings-mobileOnlyLabel {
  display: none;
  @media screen and (max-width: $euiBreakpointM) {
    display: block;
  }
}

.ilmSettings-desktopOnlyLabel {
  @media screen and (max-width: $euiBreakpointM) {
    display: none;
  }
}

.ilmSettings-nodeAttributeTags {
  margin-left: $euiSizeL;
}
