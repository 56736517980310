/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '../../styles/variables.scss';

.alert {
  padding: $paddingBase;
  margin-bottom: $paddingLargest;
  border-radius: $borderRadius;

  &:last-child {
    margin-bottom: 0;
  }
}

.alert a {
  color: $euiColorEmptyShade;
  text-decoration: underline;
}

.alert--success {
  background-color: $euiColorSuccess;
  color: $euiColorEmptyShade;
}

.alert--info {
  background-color: $euiColorPrimary;
  color: $euiColorEmptyShade;

  pre {
    color: $euiColorEmptyShade;
  }
}

.alert--warning {
  background-color: $euiColorWarning;
  color: $euiColorEmptyShade;
}

.alert--danger {
  background-color: $euiColorDanger;
  color: $euiColorEmptyShade;

  pre {
    color: $euiColorEmptyShade;
  }
}

.alert--inline {
  margin: 0;
  padding: $paddingTiny $paddingSmall;
}

.alert--top {
  border-radius: 0;
  margin-bottom: $paddingLarge;
}
