/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
@import '../../../../styles/variables.scss';

.save-credentials-modal {
  min-height: 630px;

  .euiModal__closeIcon {
    display: none;
  }
}

.save-credentials-modal-prompt {
  padding-top: $paddingSmaller;
  padding-bottom: 0;

  .save-credentials-modal-title {
    font-size: $euiFontSizeL;
    font-weight: 400;
    line-height: 2rem;
  }

  img {
    height: 190px;
    width: 190px;
  }

  .euiPanel {
    padding: 1px;
    background-color: $euiPageBackgroundColor;
    box-shadow: none;

    .euiTheme-dark & {
      background-color: $euiColorDarkestShade;
    }
  }
}
  