/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
@import '../../styles/variables.scss';

.cuiAccordion {
  .euiAccordion__triggerWrapper {
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-end;
  }

  &.euiAccordion-isOpen {
    .cuiAccordionButton {
      align-self: center;
    }
  }
}

.cuiAccordionButton {
  padding-left: $euiSizeS;
  color: $euiColorPrimary;
  flex: 0 1 0;
}
