/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
@import '../../../../../../styles/variables.scss';

.subscriptionModalBody .euiModalBody__overflow {
  padding-top: $paddingLargest;
}

.updateSubscriptionModal .euiModal__flex {
  @media screen and (min-width: $euiBreakpointS) {
    max-height: 835px;
  }
}

// This fixes the bullet points on mobile
.subscriptionFeatures {
  @media screen and (max-width: $euiBreakpointM) {
    .featureBullet, .featureText {
      flex-basis: 0!important;
      padding-left: $paddingSmall;
    }
  }
}

.cardHeader {
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscriptionCardRule {
  margin-bottom: $paddingLargest;
}
