/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '../../styles/variables.scss';

.deploymentCard-wrapper {
  min-width: 0; //Fixes bug in Firefox handling of flex-basis when used with white-space: nowrap which causes EuiFlexGrid items to wrap inconsistently
}

@media only screen and (min-width: $euiBreakpointM) {
  .euiFlexGrid .deploymentCard-wrapper { // specificity is necessary because otherwise list breaks in Customer Overview page
    flex-basis: calc(50% - #{$euiSizeL});
  }
}

@media only screen and (min-width: $euiBreakpointXL) {
  .euiFlexGrid .deploymentCard-wrapper { // specificity is necessary because otherwise list breaks in Customer Overview page
    flex-basis: calc(33.3% - #{$euiSizeL});
  }
}
