/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '../../../../../styles/variables.scss';

.cost-analysis-prepaid-balance {
  .euiHorizontalRule {
    display: none;
  }

  .cost-analysis-prepaid-balance-name {
    white-space: nowrap;
  }

  .elastic-consumption-units {
    justify-content: flex-end;
  }

  .elastic-consumption-units-unit {
    font-size: $euiFontSize;
    font-weight: normal;
  }
}

@media screen and (max-width: $euiBreakpointL) {
  .cost-analysis-prepaid-balance {
    .euiDescriptionList {
      .euiDescriptionList__title {
        width: 100%;
      }

      .euiDescriptionList__description {
        padding-left: 0;
        text-align: left;
      }
    }

    .elastic-consumption-units {
      justify-content: flex-start;
    }
  }
}
