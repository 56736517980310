/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '../../../../styles/variables.scss';

.cost-analysis-rate-panel {
  min-height: 8.75rem;

  .cost-analysis-rate-panel-price {
    font-size: $euiFontSizeXL;
    font-weight: 300;
  }

  .cost-analysis-rate-panel-summary {

    .euiDescriptionList__title,
    .euiDescriptionList__description {
      margin-top: 0;
    }

    .euiDescriptionList__description {
      text-align: right;
    }
  }

  .elastic-consumption-units-unit {
    font-weight: 700;
  }
}
