/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '../../styles/variables.scss';

.cloud-navigation {
  .euiSideNavItem--root {
    margin-top: 0.75rem;

    &:first-child {
      margin-top: 0;
    }
  }

  .euiSideNavItem--trunk > .euiSideNavItemButton-isOpen .euiSideNavItemButton__label {
    color: $euiColorDarkShade;
  }

  .euiTheme-dark & .euiSideNavItem--trunk > .euiSideNavItemButton-isOpen .euiSideNavItemButton__label {
    color: $euiColorMediumShade;
  }

  .euiSideNavItemButton-beta {
    // We use flex for layout, which break's the side nav styling's assumptions.
    // Restore the correct styling.

    @include euiTitle('xs');

    .euiSideNavItemButton__label {
      color: $euiColorFullShade;
    }

    .euiTheme-dark & .euiSideNavItemButton__label {
      color: #DDD;
    }
  }
}
