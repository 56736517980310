/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

@import '../../../../../styles/variables.scss';

.topologyFilterAllocators-filterClauses {
  list-style-type: none;
  margin: 0;
  padding: 0 0 .5rem;

  & & {
    margin: 0 0 0 7.2rem; // inset and stack inner clauses
  }
}

.topologyFilterAllocators-filterClause-outer {
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    border-left: 2px solid darken($euiColorLightestShade, 4%);
    height: 100%;
    position: absolute;
    left: 3rem;
    bottom: 0;
    z-index: -1;
  }
}

.topologyFilterAllocators-filterClauses .topologyFilterAllocators-filterClauses .topologyFilterAllocators-filterClause {
  // stack inner clauses on top of each other
  margin-top: -1px;
}

.topologyFilterAllocators-filterClause-details {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border: 1px solid darken($euiColorLightestShade, 6%);
  margin-bottom: $paddingBase;

  &+& {
    margin-bottom: 0;
    margin-top: -1px;
  }
}

.topologyFilterAllocators-filterClause-connector {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $euiColorLightestShade;
  border-right: 1px solid darken($euiColorLightestShade, 6%);
  width: 6rem;
}

.topologyFilterAllocators-filterClause-fields {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: $paddingLargest;

  input {
    // tighten up the textboxes a bit
    padding: 6px 10px;
    box-shadow: none;
    border-right: 1px solid darken($euiColorLightestShade, 6%);
    border-left: 1px solid darken($euiColorLightestShade, 6%);
    border-radius: 0;
  }
}

.topologyFilterAllocators-filterClause-fields-equal-to {
  white-space: nowrap;
  padding: 0 $paddingLarge;

}

.topologyFilterAllocators-removeClause {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  padding: $paddingSmaller;
}

.topologyFilterAllocators-filterClause-actions {
  >* {
    display: inline-block;
    margin-right: .5rem;
  }
}
